<template>
  <div class="table-item-orders">
    <base-table
      :columns="columns"
      :list="paginList"
      class="table-item-orders__table"
      @sort-change="onSortChange"
    >
      <base-table-row
        v-for="item in paginList"
        :key="item.service.id"
        :item="item.service"
        :columns="columns"
      >
        <template #date>
          <span class="note-color">{{ $d(item.service.createdate, 'short') }}</span>
        </template>
        <template #title>
          {{ item.service.title }}
        </template>
        <template #cost>
          <span class="note-color">{{ $n(item.service.cost, 'currency') }}</span>
        </template>
        <template #status>
          <main-label :color="item.service.status.color" theme="plain">
            {{ $t(`status.${item.service.status.key}`) }}
          </main-label>
        </template>
        <template #docs>
          <span class="note-color">{{ item.service.instruction }}</span>
        </template>
      </base-table-row>
    </base-table>
    <NewPagination
      :step="pagin.step"
      :start="pagin.start || 0"
      :count="list.length"
      type="small"
      @set-step="setStep"
      @new-pagination="changePagination"
    />
  </div>
</template>

<script>
import BaseTable from '@/components/Table/BaseTable.vue';
import BaseTableRow from '@/components/Table/BaseTableRow.vue';
import MainLabel from '@/components/Labels/MainLabel.vue';
import NewPagination from '@/components/Pagination/NewPagination';
import pagination from '@/mixins/pagination';
export default {
  name: 'TableItemOrders',
  components: {
    BaseTable,
    BaseTableRow,
    MainLabel,
    NewPagination,
  },
  mixins: [pagination],
  props: {
    columns: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      stepOptions: [5, 10, 25, 50, 100, 'all'],
      pagin: {
        start: 0,
        step: 5,
      },
    };
  },
  computed: {
    list() {
      return this.$store.getters['moduleBilling/moduleOrders/moduleItemlist/GET_SORTED_LIST'];
    },
    sort() {
      return this.$store.state.moduleBilling.moduleOrders.moduleItemlist.sort;
    },
    /*paginList() {
      if (this.pagin.step === 'all') return this.list;
      const { start, step } = this.pagin;
      const end = start + step;
      return this.list.slice(start, end);
    },*/
    showPagination() {
      return this.list.length > this.stepOptions[0];
    },
    grouppedList() {
      const collection = {};
      this.list.reduce((acc, item) => {
        if (!acc[item.id]) acc[item.id] = { list: [], cost: 0 };
        acc[item.id].list.push(item);
        acc[item.id].cost += item.service.cost;
        return acc;
      }, collection);
      // console.log(collection);
      return collection;
    },
  },
  watch: {
    sort: {
      handler(val) {
        this.updateSort(val);
      },
      deep: true,
    },
  },
  mounted() {
    this.updateSort(this.sort);
    // console.log(this.grouppedList);
  },
  methods: {
    onSortChange(payload) {
      let { prop, order } = payload;
      if (this.sort.prop === prop) order = order === 'asc' ? 'desc' : 'asc';
      this.$store.commit('moduleBilling/moduleOrders/moduleItemlist/SET_SORT', { prop, order });
    },
    updateSort(sort) {
      const col = this.columns.find(i => i.sort && i.sort.prop === sort.prop);
      col.sort.order = sort.order;
    },
    /*setStep(value) {
      this.pagin.step = value;
    },
    changePagination(value) {
      this.pagin.step = value.rows;
      this.pagin.start = value.first;
    },*/
  },
};
</script>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.table-item-orders {
  &__table {
    +breakpoint(md-and-up) {
      max-width: calc(100% - 146px);
    }
  }
  &__pagination {
    margin-top: 1.5rem;
  }
}
</style>
